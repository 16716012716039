//
// Content
//

// Desktop mode
@include media-breakpoint-up(lg) {
  .content {
    padding: get($content-spacing, desktop) 0;
  }
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .content {
    padding: get($content-spacing, tablet-and-mobile) 0;
  }
}

.content-height {
  height: calc(100vh - 68px)
}

// Tablet & mobile modes
@include media-breakpoint-down(lg) {
  .content-height {
    height: calc(100vh - 68px)
  }
}