.wrap-create-custom-group-2-search-input {
	display: flex;
  position: relative;
  flex: 1;
	justify-content: center;
	align-items: center;
  min-width: 200px;
	border: 1px solid grey;
	border-radius: 5px;
	padding: 8px;
}

.custom-group-category-filter-button {
  margin: 10px;
  background-color: #0491BD;
  color: #fff;
  border: 1px #0491BD solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.675rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 118, 155, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  /* box-shadow: var(--bs-btn-box-shadow); */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-group-category-filter-outline-button {
  margin: 10px;
  background-color: #fff;
  color: #5C5C5C;
  border: 1px #CBCBCB solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.675rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.container-custom-groups-card {
	display: flex;
	flex-wrap: wrap;
}

.card-custom-groups-div {
	margin-right: 20px;
	margin-bottom: 20px;
	width: 31%;
	min-width: 300px;
}

.card-custom-groups-header-div {
	border: 1px solid #d8d8d8;
	border-width: 0px 0px 1px 0px;
	padding: 20px 10px;
}

.card-custom-groups-body-div {
	padding: 10px;
	margin-left: 36px;
}

.card-custom-group-live-indicator {
	position: absolute;
	right: 0px;
	top: 8px;
	height: 6px;
	width: 6px;
	border-radius: 3px;
	background-color: #1ac400;
	margin-left: 10px;
}

.card-custom-groups-employee-badge {
	background-color: #EDEDED;
	padding: 2px 10px;
	margin-right: 6px;
	margin-bottom: 6px;
	border-radius: 4px;
}

.datepicker-styles {
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 600;
}

.create-group-model-title {
  padding: 2px 10px;
  width: fit-content;
  outline: none;
  font-size: 22px;
  font-weight: 800;
  color: #666666;
  background-color: #fafdff;
  border: 1px solid #afaeae;
  border-width: 0px 0px 1px 0px;
  border-radius: 5px;
}