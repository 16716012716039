.login-page {
    display: grid;
    grid-template-columns: 4fr 7fr;
    height: 100vh;
    position: relative;
  }
  
  .login-form-div {
    background-color: white;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

.logo-img-1 {
  height: 80px;
  position: absolute;
  top: 10px;
  left: 40px;
}
  
  .login-form-div h1 {
    font-size: 2.5rem;
    font-weight: 400;
    margin-bottom: 2rem;
    text-align: start;
  }
  
  .login-form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 60%;
    font-size: 1.2rem;
  }
  
  .login-form label {
    font-weight: bold;
  }
  
  .login-form input {
    padding: 0.5rem;
    border: 1px solid #ccc;
    border-radius: 4px;
  }

.label-pwd {
  margin-top: 1rem;
}
  
.login-form button {
    background-color: #0491bd;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 2rem;
  }

.login-form input:focus {
  outline: none;
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
}

.login-form button:hover {
  -webkit-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
-moz-box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
box-shadow: 0px 2px 8px 0px rgba(0,0,0,0.25);
}

.highlighted-blue {
  color: #009EF7;
  font-size: 3.5rem;
}
  
  .carousel {
    background-image: url('/src/_metronic/assets/images/loginForestBackground.jpg'); /* Replace with your image URL */
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    position: relative;
  }

  .carousel-in-wrapper {
    padding-top: 10%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .carousel-container {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 90%;
    position: relative;
  }
  
  .carousel-text {
    flex: 0 0 100%;
    padding: 2rem;
    /* text-align: center; */
  }

  .carousel-text h1 {
    color: white;
    font-size: 3rem;
    font-weight: 400;
  }
  
  .carousel-indicators {
    margin-bottom: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .indicator {
    width: 10px;
    height: 10px;
    background-color: rgba(255, 255, 255, 0.5);
    border-radius: 50%;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .indicator.active {
    background-color: white;
  }

.shadow-div {
  position: absolute;
  background-color: #11111166;
  border-radius: 50%;
  width: 80vw;
  height: 80vh;
  bottom: -10vh;
  left: -10vw;
  rotate: 10deg;
}


  /* Responsive styles */
@media screen and (max-width: 1100px) {
    .login-page {
      grid-template-columns: 4fr 5fr;
    }
  
    .login-form {
      padding: 1rem;
    }
  }


@media screen and (max-width: 800px) {
    .login-page {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr;
    }
  
    .login-form-div {
      padding: 1rem;
    }
  
    .carousel {
      display: none;
    }
  }


@media screen and (max-width: 600px) {
  .login-form {
    width: 80%;
  }

}


  