#kt_content_container {
    padding: 0px !important;
}


@keyframes fillAnimation{
  0%{transform : rotate(-45deg);}
  50%{transform: rotate(135deg);}
}
  
@keyframes fillGraphAnimation{
  0%{transform: rotate(0deg);}
  50%{transform: rotate(180deg);}
}


.semi-donut-model{
  width: 160px;
  height: 80px;
  position: relative;
  text-align: center;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  border-radius: 160px 160px 0 0;
  overflow: hidden;
  color: rgb(35, 43, 71);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
  &:before, &:after{
    content: '';
    width: 160px;
    height: 80px;
    border:10px solid rgb(35, 43, 71);
    border-top: none;
    position: absolute;
    transform-origin :50%  0% 0;
    border-radius: 0 0 160px 160px ;
    box-sizing : border-box;
    left: 0;
    top: 100%;
  }
  &:before {
    border-color:rgba(0,0,0,.15);
    transform: rotate(180deg);
  }
  &:after{
      z-index: 3;
      animation : 1s fillGraphAnimation ease-in;
    transform: rotate( calc( 1deg * ( var(--percentage) * 1.8 ) ) );
  }
  &:hover{
      &:after{
        opacity: .8;
        cursor: pointer;
      }
  }
}

.semi-donut-model2{
  width: 140px;
  height: 70px;
  position: relative;
  text-align: center;
  font-size: 22px;
  font-weight: 600;
  border-radius: 140px 140px 0 0;
  overflow: hidden;
  color: rgb(255, 255, 255);
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
  &:before, &:after{
    content: '';
    width: 140px;
    height: 70px;
    border:10px solid rgb(207, 217, 255);
    border-top: none;
    position: absolute;
    transform-origin :50%  0% 0;
    border-radius: 0 0 160px 160px ;
    box-sizing : border-box;
    left: 0;
    top: 100%;
  }
  &:before {
    border-color:rgba(255, 255, 255, 0.15);
    transform: rotate(180deg);
  }
  &:after{
      z-index: 3;
      animation : 1s fillGraphAnimation ease-in;
    transform: rotate( calc( 1deg * ( var(--percentage) * 1.8 ) ) );
  }
  &:hover{
      &:after{
        opacity: .8;
        cursor: pointer;
      }
  }
}

.custom-header-dropdown {
  background-color: #181c32 !important;
  color: #fff !important;
  padding: 5px 10px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  border: 0.5px solid #fff;
}

.timezone-text{    
  align-self: center;
  margin-right: 20px;
  margin-top: 10px;
}
