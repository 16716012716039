.carousel-inner {
  height: 100% !important;
}

.gm-control-active {
  z-index: 1000 !important;
}

.carousel-control-prev, .carousel-control-next {
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-line-grey-2 {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 150%;
  z-index: 400;
  left: 0px;
}

.pagination {
  position: absolute;
  bottom: 16px; /* Tailwind bottom-4 = 1rem = 16px */
  left: 50%;
  transform: translateX(-50%); /* Centers the element horizontally */
  display: flex;
  gap: 8px; /* Tailwind gap-2 = 0.5rem = 8px */
}

/* Button styles for "w-2 h-2 rounded-full transition-colors" */
.pagination-btn {
  width: 8px; /* Tailwind w-2 = 0.5rem = 8px */
  height: 8px; /* Tailwind h-2 = 0.5rem = 8px */
  border-radius: 50%; /* Rounded circle */
  background-color: rgba(0, 255, 255, 0.5); /* Default white/50 */
  transition: background-color 0.3s ease; /* Smooth transition on color change */
  border: none;
  cursor: pointer;
}

/* Active state for "bg-white" */
.pagination-btn.active {
  background-color: rgb(186, 188, 36); /* Fully opaque white for active button */
}

/* Optional: For accessibility, you can add focus styles */
.pagination-btn:focus {
  outline: 2px solid #ddd;
}

.imageContainer {
  align-items: center;
  align-self: center;
  height: 500px;
  width: 800px;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: grab;
}

.imageCaraousel {
  width: 400px;
  height: 400px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  position: relative;
  cursor: grab;
}

.horizontal-header-card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid #d4d9df;
  border-radius: 10px;
  width: 100%;
}

.forest-card {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F2F2F2;
  border: 1px solid #d4d9df;
  border-radius: 10px;
  padding: 15px;
}

.forest-docs-page-card {
  flex: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #F2F2F2;
  border: 1px solid #d4d9df;
  border-radius: 10px;
  padding: 15px;
}

.downloadable-docs-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #F2F2F2;
  border: 1px solid #d4d9df;
  border-radius: 2px;
  padding: 15px;
}


.timeline-slider {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0 auto;
}


/* Enhanced styling for the slider */
.slider {
  width: 100%;
  -webkit-appearance: none;
  height: 10px;
  background: linear-gradient(to right, #007bff 0%, #007bff 0%, #ddd 0%, #ddd 100%);
  border-radius: 5px;
  outline: none;
  position: relative;
  cursor: pointer;
  transition: background 0.3s ease;
  margin-bottom: 30px; /* Spacing for the years below */
}

/* Add progress fill */
.slider::-webkit-slider-runnable-track {
  width: 100%;
  height: 10px;
  background: transparent;
}

.slider::-moz-range-track {
  width: 100%;
  height: 10px;
  background: transparent;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 30px;
  height: 30px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Custom thumb on Firefox */
.slider::-moz-range-thumb {
  width: 30px;
  height: 30px;
  background-color: #007bff;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  position: relative;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

/* Thumb hover/active/focus styles */
.slider:hover::-webkit-slider-thumb,
.slider:focus::-webkit-slider-thumb,
.slider:active::-webkit-slider-thumb {
  transform: scale(1.2);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

.slider:hover::-moz-range-thumb,
.slider:focus::-moz-range-thumb,
.slider:active::-moz-range-thumb {
  transform: scale(1.2);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.2);
}

/* Fade in and fade out animation */
.carousel-image {
  max-width: 100%;
  height: auto;
  transition: opacity 0.5s ease-in-out;
}

.fade-in {
  opacity: 1;
}

.fade-out {
  opacity: 0;
}

/* Styling for the year labels below the slider */
.year-labels-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 10px;
  margin-top: -15px; /* Adjust based on slider thumb size */
}

.year-label {
  font-size: 14px;
  color: #333;
  text-align: center;
  position: relative;
  transform: translateX(-50%);
  transition: color 0.3s ease;
  cursor: pointer;
}

/* Highlight current year */
.year-label.active {
  font-weight: bold;
  color: #463578;
}

/* Optional hover effect */
.year-label:hover {
  color: #463578;
}


.timeline-container-range {
  display: flex;
  align-items: center;
  margin: 20px;
  width: 50%;
}

.timeline-label {
  font-size: 1.2rem;
  margin: 0 10px;
}

.timeline-label::before {
  width: 0;
}

.timeline-wrapper {
  position: relative;
  width: 100%;
  height: 40px;
}

/* Updated thumb with radial gradient of purple */
.timeline-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background: radial-gradient(circle, #8962C2 0%, #5a529c 70%, #403174 100%);
  border-radius: 50%;
  cursor: pointer;
}

.timeline-range::-moz-range-thumb {
  width: 22px;
  height: 22px;
  background: radial-gradient(circle, #8962C2 0%, #5a529c 70%, #403174 100%);
  border-radius: 50%;
  cursor: pointer;
}

/* Tooltip with arrow pointed upwards */
.range-tooltip {
  position: absolute;
  top: -40px;
  transform: translateX(-50%);
  background-color: #6c6c6c;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 1;
}

.range-tooltip::before {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #6c6c6c transparent;
}

.timeline-range {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  outline: none;
  border-radius: 2px;
  position: absolute;
  top: 16px;
}
.timeline-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background: radial-gradient(circle, #8962C2 0%, #5a529c 70%, #403174 100%);
  border-radius: 50%;
  cursor: pointer;
}

.timeline-range::-moz-range-thumb {
  width: 22px;
  height: 22px;
  background: radial-gradient(circle, #8962C2 0%, #5a529c 70%, #403174 100%);
  border-radius: 50%;
  cursor: pointer;
}

/* Tooltip with arrow pointed upwards */
.range-tooltip {
  position: absolute;
  top: 40px;
  transform: translateX(-50%);
  background-color: #6c6c6c;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 1;
}

.range-tooltip::before {
  content: '';
  position: absolute;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #6c6c6c transparent;
}