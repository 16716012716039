.custom-content {
  padding: 2rem;
  margin-top: 0rem !important;
  background-color: white;
  font-family: 'Nunito', sans-serif !important;
}

.title {
  color: #464e5f;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
  font-size: 22px;
}

.vertical-divider {
  width: 1px;
  height: 80px;
  align-self: center;
  border-width: 0.5px;
  border-color: #d8d8d8;
  border-style: solid;
  background-color: #d8d8d8;
}

.hightlight-ribbon-wrap {
  /* width: 100%; */
}

.net-zero-year-blue-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background-color: #E2F7FE9C;
  border: 1px solid #83BED1; 
  border-radius: 10px;
}

.highlight-round-img {
  height: 80px;
  width: 80px;
  border-radius: 40px;
  /* overflow: hidden; */
  border: 8px solid #F4F9FB;
  /* -webkit-box-shadow: 4px 4px 19px 0px rgba(0,0,0,0.16);
  -moz-box-shadow: 4px 4px 19px 0px rgba(0,0,0,0.16);
  box-shadow: 4px 4px 19px 0px rgba(0,0,0,0.16); */
}

.highlights-all-cards-wrap {
  display: flex;
  height: 80%;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 10px;
}

.highlights-card-wrapper {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  padding: 5px 20px;
  margin-bottom: 5px;
  border: 1px solid #d8d8d8;
  border-radius: 10px;
}

.vertical-divider-middle {
  width: 1px;
  height: 180px;
  align-self: center;
  border-width: 0.5px;
  border-color: #d8d8d8;
  border-style: solid;
  background-color: #d8d8d8;
}

.vertical-divider-long {
  width: 1px;
  height: 250px;
  align-self: center;
  border-width: 0.5px;
  border-color: #d8d8d8;
  border-style: solid;
  background-color: #d8d8d8;
}

h3 > span {
  color: #464e5f !important;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
  font-size: 22px;
}

.prompt-title {
  font-family: 'Nunito', sans-serif;
  font-size: 21px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
}

.prompt-body {
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;
}
.custom-select {
  max-width: fit-content;
  max-height: 3rem;
}

/* .btn-primary {
    background-color: #004054 !important;
} */

.btn-primary-pass {
  background-color: #f6f1f1 !important;
  border: 1 !important;
}

.settings {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.p-12 {
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  padding: 0rem 8rem 2rem 8rem !important;
}

.p-10 {
  font-family: 'Nunito', sans-serif;
  font-size: 15px;
  padding: 4rem 8rem 2rem 8rem !important;
}

.settings-btn {
  display: flex;
  justify-content: flex-end;
}

.settings-btn-bottom {
  display: flex;
  justify-content: space-between;
}

.btn-while {
  box-shadow: #004054;
  border: 1px solid;
  border-radius: 20;

  display: inline-flex;
  align-items: center;
}

.row-1 {
  display: flex;
  flex-direction: row;
}

.col-1 {
  display: flex;
  flex-direction: column;
}

.align-bottom {
  align-self: flex-end;
}

.text-title {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
}

.text-description {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0;
  text-align: left;
}

.text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.des {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
}

.input-text {
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
}

.heading {
  display: flex;
  justify-content: center;
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.p-1 {
  display: flex;
  justify-content: center;
  font-family: Nunito;
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
}

.password-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 2rem;
}

.background-image {
  position: absolute;
  z-index: 0;
  bottom: 0;
  right: 0;
  width: 10%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8rem 12rem 15rem 10rem !important;
}

.background-image-f {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 10%;
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 8rem 14rem 15rem 10rem !important;
}

.forest-h {
  font-family: Nunito;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: center;
}

.sub-text {
  font-family: Nunito;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: center;
  color: #666666;
}

.sub-heading {
  font-family: Nunito;
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  color: #666666;
}

.user-profile-bg {
  background-image: url('/public/media/svg/shapes/u_user.svg');
}

.lock-bg {
  background-image: url('/public/media/svg/shapes/lock-bg.svg');
}

.pencil-bg {
  background-image: url('/public/media/svg/shapes/u_edit-alt.svg');
}

.forest-bg {
  background-image: url('/public/media/svg/shapes/u_trees.svg');
}

.add-bg {
  background-image: url('/public/media/svg/shapes/u_plus.svg');
}

.shadow {
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.2);
}

.bottom {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.spread {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  position: absolute;
  bottom: 0.3%;
  width: -webkit-fill-available;
}

.text-forest {
  font-family: Nunito;
  width: 513px;
  height: 18px;
  top: 614px;
  left: 628px;
  border-radius: 3px;
  size: 13px;
}

.extrapolation-banner {
  /* position: sticky;
  top: 0.5rem;
  z-index: 100000; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  /* margin: 5px 24px; */
  margin-bottom: 15px;
  background-color: #EDFAFF;
  border: 1px solid #83BED1;
  border-radius: 10px;
}

.extrapolation-banner p {
  font-family: Nunito;
  font-size: 1.2rem;
}

/* TOGGLE EXTRAPOLATION  */

.extrapolation-banner .switch {
  position: relative;
  display: inline-block;
  width: 54px;
  height: 28px;
}

.extrapolation-banner .switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.extrapolation-banner .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.extrapolation-banner .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

.extrapolation-banner input:checked + .slider {
  background-color: #2196F3;
}

.extrapolation-banner input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

.extrapolation-banner input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.extrapolation-banner .slider.round {
  border-radius: 34px;
}

.extrapolation-banner .slider.round:before {
  border-radius: 50%;
}
