/* ChatButton.css */
.sync-floating-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 20px;
  right: 30px;
  background-color: #232b47;
  color: #fff;
  /* border: 1.5px solid white; */
  border: none;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  cursor: pointer;
  font-size: 18px;
  box-shadow: 2px 5px 10px rgba(0, 0, 0, 0.5);
}

.sync-floating-widget {
  position: fixed;
  width: 300px;
  bottom: 100px;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.sync-floating-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #232b47;
  color: #fff;
  padding: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.sync-floating-header .sync-floating-close {
  float: right;
  cursor: pointer;
  font-size: 24px;
}

.sync-floating-body {
  padding: 15px;
  padding-bottom: 0px;
}

.sync-floating-header-text {
  color: '#FFF'; 
}
