.anchor-blue-p-link {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
  color: #0491BD; 
  text-decoration: underline;
  cursor: pointer;
}

.csv-file-drop-div {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	background-color: #FCFCFC;
	padding-top: 2rem;
	padding-bottom: 2rem;
	width: 60%;
	border-radius: 5px;
	border: 2px dashed #B5B5C3;
}

.edit-employee-header-wrap {
	display: flex;
	align-items: center;
}

.edit-employee-name-header {
	font-size: 3rem;
	color: #747474;
	font-weight: 800;
}

.edit-employee-email-header {
	font-size: 1.5rem;
	color: #747474;
}

.uploaded-csv-file-name-wrap {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 10px;
	margin-bottom: 5px;
	border: 1px solid #707070;
	border-radius: 5px;
}

/* Custom styling for the file input */
.upload-csv-input {
	display: none; /* Hide the default file input */
}

.upload-csv-file-name {
	color: #666666;
	font-weight: 600;
	font-size: 1.2rem;
	margin: 0px 40px 0px 10px;
}

.card-wrapper-edit-employee {
	padding: 3.5rem;
}

.edit-employee-image-wrapper {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding-left: 2rem;
	min-width: 160px;
}

.edit-employee-image {
	height: 10rem;
	width: 10rem;
	border-radius: 6rem;
	margin-right: 10px;
}

.drag-file-element {
	background-color: pink;
	z-index: 100;
	position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.wrap-employee-search-input {
	display: flex;
	justify-content: center;
	align-items: center;
	border: 1px solid grey;
	border-radius: 5px;
	padding: 8px;
	margin: 10px;
}

.employee-search-input {
	border-width: 0;
	outline: none;
	font-size: 15px;
	font-weight: 600;
}