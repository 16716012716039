.multi-select-check-dropdown {
  position: relative;
  border: 1px solid #bababa;
  border-radius: 8px;
  /* display: inline-block; */
}

.multi-select-check-dropdown-button {
  color: #202020;
  background-color: #ffffff;
  overflow: hidden;
  border-radius: 20px;
  padding: 10px 12px;
  font-size: 14px;
  font-weight: 500;
  border: none;
  width: 100%;
  cursor: pointer;
  text-align: left;
}

.multi-select-check-dropdown-content {
  display: block;
  position: absolute;
  margin-top: 5px;
  max-height: 300px;
  scroll-behavior: smooth;
  overflow-y: scroll;
  background-color: white;
  width: 100%;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  padding: 5px;
  border: 1px solid #ddd;
  border-radius: 10px;
}

.multi-select-check-dropdown-content label {
  display: block;
  padding: 10px 8px;
  cursor: pointer;
}

.multi-select-check-dropdown-content label:hover {
  background-color: #f1f1f1;
  border-radius: 5px;
}

.multi-select-check-dropdown [type="checkbox"] {
  margin-right: 8px;
}

.card-polls-participant-type-pill {
  position: absolute;
  padding: 4px 8px;
  background-color: #397888;
  color: #ffffff;
  border-radius: 5px;
  bottom: -15px;
  right: 5px;
}

.view-image-polls-modal-width {
  width: 300px;
  align-self: center;
}
