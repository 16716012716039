.forest-empty-div {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #E8E7E7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 4rem;
    padding-bottom: 4rem; 
}

.forest-empty-div h3 {
    margin-bottom: 2rem;
    color: #444444;
    font-weight: 400;
}

.mixed-empty-div {
    background-color: white;
    border-radius: 10px;
    border: 1px solid #E8E7E7;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-top: 5rem;
    padding-bottom: 6rem; 
}

.mixed-empty-div h3 {
    margin-bottom: 3rem;
    color: #444444;
    font-weight: 400;
}