.custom-header {
  /* padding: 1rem;
  padding-left: 2rem;
  padding-right: 2rem; */
  background-color: white !important;
  display: flex;
  flex: 1;
  flex-direction: row;
  border-width: 0 0 0.5px 0;
  border-color: #ffffff;
  border-style: solid;
  justify-content: space-between;
  align-items: stretch;
  font-family: 'Nunito', sans-serif !important;
  color: #ffff;
  position: sticky;
  top: 0;
  z-index: 2;
}

.sticky-div-wrap {
  position: sticky;
  top: 1rem;
  /* top: 4.9rem; */
}

.custom-header2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-family: 'Nunito', sans-serif !important;
}

.flex1-min-wt {
  flex: 1; 
  min-width: 100%;
}

.card-wid4-div {
  flex: 1;
  min-width: 100%;
  margin: 2rem;
}

.custom-header-card {
  border-radius: 10px;
  background-color: #232b47;
  padding: 1rem;
  flex: 1;
  margin-right: 1rem !important;
}

.chc-title {
  margin-bottom: 1rem;
}

.custom-header-card2 {
  border-radius: 10px;
  padding: 1rem;
  /* border: 1px solid #e5eaee; */
  border: 1px solid #d8d8d8;
  flex: 1;
}

.custom-header-card22 {
  padding: 1rem;
  /* border: 1px solid #E5EAEE; */
  border: 1px solid #d8d8d8;
  flex: 1;
  border-width: 0px;
}

.chc-title2 {
  margin-bottom: 1rem;
}

.chc-title22 {
  margin-bottom: 1rem;
  flex: 1;
}

.no-border-radius-rg {
  border-radius: 10px 0px 0px 10px;
}

.no-border-radius-lf {
  border-radius: 0px 10px 10px 0px;
}

.no-border-radius-sides {
  border-radius: 0px;
  border-width: 1px 0px 1px 0px;
}

.border-all {
  border-radius: 10px;
  border: 1px solid #E5EAEE;
}

.border-top-left {
  border-right-width: 1px;
  border-bottom-width: 1px;
}

.border-top-right {
  border-bottom-width: 1px;
}

.border-bottom-left {
  border-right-width: 1px;
}

.custom-header-settings {
  margin: 0px !important;
  padding: 8px !important;
  background-color: white !important;
  display: flex;
  flex-direction: row;
  border-width: 0 0 0.5px 0;
  border-color: #f4f4f4;
  border-style: solid;
  justify-content: space-between;
  align-items: stretch;
  font-family: 'Nunito', sans-serif !important;
}

.custom-card {
  display: flex;
  flex-direction: column;
  padding: 10px 20px !important;
  background-color: #fff !important;
  max-width: 30% !important;
}

.title {
  color: #464e5f;
  font-weight: 700 !important;
  letter-spacing: 0.5px !important;
  font-size: 15px;
}

.value {
  color: #464e5f;
  font-weight: 700 !important;
  font-size: 28px;
}

.sub-label {
  color: #666666;
  font-weight: 400 !important;
  letter-spacing: 0.5px !important;
  font-size: 12;
  margin-left: 5px;
}

.tips-container {
  background-color: #5cadc9 !important;
  width: 100%;
  display: flex;
  align-self: center;
  border-radius: 1rem;
  justify-content: space-between;
}

/* .tips-container h3{
    margin-bottom: 0px !important;
} */

.tips-text {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #fff !important;
}

.arrow {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  margin-right: 2px;
}

.growth {
  border-bottom: 10px solid hsl(106.79deg 45.61% 53.14%);
}

.loss {
  border-bottom: 10px solid hsl(353.57deg 90.32% 63.53%);
}

.badge-light-success {
  color: var(--bs-success);
  background-color: #fff !important;
}

.badge-light-danger {
  color: var(--bs-danger);
  background-color: #fff !important;
}

.btn-custom-date {
  background-color: rgba(0, 0, 0, 0) !important;
  border-style: solid !important;
  border-color: #fff !important;
  border-width: 0.5px !important;
  border-radius: 0;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.dropdown {
  margin-left: 2rem;
  margin-right: 4rem;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-color: #272534;
}

#mixed-widget-4-chart {
  min-height: 215px !important;
}

.aside-menu-hr {
  background-color: grey;
  height: 2px; 
  margin: 0px;
}

.aside-bar-wrapper {
  top: 69px;
}
  
.aside-menu-sec-title {
  color: white;
  font-weight: 400;
  padding-left: 1.4rem;
  margin-bottom: 0.8rem;
}

.aside-menu .menu .menu-item .menu-link.active {
  transition: color 0.2s ease;
  background-color: rgba(0, 0, 0, 0);
  color: hsl(195.38deg 45.88% 66.67%) !important;
}

.aside-menu .menu .menu-item {
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0em;
  text-align: left;
}

.aside-menu .menu .menu-item .menu-link.active .menu-title {
  color: hsl(195.38deg 45.88% 66.67%) !important;
}

.count-values {
  font-family: 'Nunito', sans-serif !important;
  font-size: 14px !important;
  font-weight: 400;
  color: #666666;
}

.percent-val {
  font-family: 'Nunito', sans-serif;
  font-size: 13px;
  color: #666666;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
}

.percent-val-bold {
  font-size: 14px;
}

.zIndex200 {
  z-index: 200;
}

.bordered-grey {
  border-radius: 10px;
  /* border: 1px solid #E8E7E7; */
  border: 1px solid #d8d8d8;
}

.breadcrumb-item a{
  color:#666666 !important;
  font-family: 'Nunito', sans-serif;
  font-size: 14px;
  font-weight: 400;
}

.breadcrumb .active{
  text-decoration: underline;
  font-size: 14px;
  font-weight: 600;
  color: #000 !important;
}

.breadcrumb{
  padding: 0px  20px;
  margin: 0px 40px;
}

.custom-header2 .flex1-min-wt div:last-child {
  margin-right: 0rem;
}
/* 
.custom-text-center{
  text-align: center;
} */

.table.gs-0 td:first-child {
  padding-left: 0rem;
  text-align: left !important;
}

.allocation-box{
  border: 1px solid #004054;
  padding: 10px 20px;
  border-radius: 10px;
  color: #0491BD;
  max-width: 25%;
  font-weight: 700;
}

.allocation-box2 {
  border: 1px solid #004054;
  padding: 10px 20px;
  border-radius: 10px;
  color: #0491BD;
  font-weight: 700;
  max-width: fit-content;
  margin: auto;
}

.d-block > .allocation-box{
  margin: auto;
}

.table.gs-0 {
  padding-left: 0rem;
  /* text-align: center !important; */
}

.spider-tooltip {
  position: absolute;
  top: 5%;
  right: 80%;
  z-index: 1;
  background-color: white;
  display: flex;
  flex-direction: column;
  min-width: max-content;
}

.mx-border-mid {
  border: 1px solid #E8E7E7;
  border-width: 0px 1px 0px 1px;
}

@media screen and (max-width: 1200px) {
  .mx-border-mid {
    border-width: 1px 0px 1px 0px;
  }
}

@media screen and (max-width: 800px) {
  .custom-header {
    flex-direction: column;
    gap: 10px;
    position: relative;
    padding-top: 20px;
  }

  .spider-tooltip {
    right: 50%;
  }
  
}
/* 
.custom-text-center{
  text-align: center;
} */

/* .table.gs-0 td:first-child {
  padding-left: 0rem;
  text-align: left !important;
} */

.allocation-box{
  border: 1px solid #004054;
  padding: 10px 20px;
  border-radius: 10px;
  color: #0491BD;
  max-width: 25%;
  font-weight: 700;
}

.d-block > .allocation-box{
  margin: auto;
}

/* .table.gs-0 {
  padding-left: 0rem;
  text-align: center !important;
} */

.category-location-heading{
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(141, 141, 141, 1);

  
}

.category-location-value{
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: left;
  font-weight: 700;
}


.top-activity-card{
  /* box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.08); */
  border: 1px solid #E8E7E7;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 6px;
  margin-top: 20px;
}

.top-activity-card > p {
  margin-bottom: 0px !important;
}

.top-activity-card > div >  p {
  margin-bottom: 0px !important;
}