.upload-data-category-filter-button {
  margin: 10px;
  background: linear-gradient(to bottom, #513F73, #3A2D56);
  color: #fff;
  border: 1px #ffffff00 solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.675rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 118, 155, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  /* box-shadow: var(--bs-btn-box-shadow); */
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.upload-data-category-filter-outline-button {
  margin: 10px;
  background-color: #fff;
  color: #5C5C5C;
  border: 1px #CBCBCB solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.675rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: 8px 16px;
  font-size: 16px;
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.upload-data-subcategory-filter-button {
  margin: 10px;
  background-color: #EBE5F5;
  color: #000;
  border: 1px #ffffff00 solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.675rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0 2px 0 rgba(255, 255, 255, 0.15), 0 2px 2px rgba(39, 118, 155, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  width: 100%;
  height: 100%;
  padding: 12px;
  font-size: 15px;
  font-weight: 700;
  line-height: var(--bs-btn-line-height);
  text-align: left;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 16px;
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.upload-data-subcategory-filter-outline-button {
  margin: 10px;
  background-color: #f5f5f5;
  color: #000;
  border: 1px #f0eeee solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.675rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.375rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0 2px 0 rgba(255, 255, 255, 0.15), 0 2px 2px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  height: 100%;
  width: 100%;
  padding: 12px;
  font-size: 15px;
  font-weight: 700;
  line-height: var(--bs-btn-line-height);
  text-align: start;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: 16px;
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}