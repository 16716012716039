.page-subtitle-text-grey {
  font-size: 1.3rem;
  color: #666666;
  margin: 0px;
}

.employee-count-container-div {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 25px;
  border: 1px solid #666666;
  border-radius: 5px;
}

.svg-edit-icon-msdata {
  height: 30px;
  width: 30px;
}

.office-label-span {
  font-size: 13px;
  color: #666666;
}

.location-maps-wrapper {
  border-radius: 10px;
  overflow: hidden;
  margin-top: 10px;
}

.address-text-maps-wrapper {
  padding: 15px 20px;
  background-color: #f4f4f4;
}

.address-text-maps-wrapper h6 {
  font-size: 16px;
  /* color: #666666; */
}

.address-dropdown-container {
  position: absolute; 
  top: 110%;
  z-index: 1000;
  border-radius: 5px;
  border: 1px solid rgb(192, 192, 192); 
  overflow: hidden;
}

.address-dropdown-item {
  background-color: white;
  padding: 8px 10px;
  width: 500px;
  border: 1px solid rgb(195, 195, 195); 
  border-width: 0px 0px 1px 0px;
}

.address-dropdown-item:hover {
  background-color: #ebebeb;
}

.location-table-address-wrap {
  /* width: 50%; */
  justify-content: center;
  /* align-items: center; */
}

.location-table-address-text {
  text-align: center;
}

.action-sec-container-div {
  margin-top: 1rem;
}

.tabs-list-container {
  border-color: #a3a3a3;
}

.tabs-list-container .nav-link {
  color: #666666;
  font-size: 1.2rem;
}

.tabs-list-container .nav-link.active {
  font-weight: 600;
  border-color: #a3a3a3;
  border-bottom-color: #FFFFFF;
}

.tabs-list-container .nav-link:hover {
  border-bottom-color: #FFFFFF;
  /* color: #009ef7; */
}

.master-data-add-new-btn {
  position: absolute;
  top: -58px;
  right: 0px;
  padding: 7px 16px;
}

.modal-input-field {
	border: 1px solid grey;
	border-radius: 5px;
	outline: none;
	font-size: 15px;
	font-weight: 600;
  width: 100%;
  padding: 8px;
}