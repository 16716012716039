.custom-margin {
  margin: 0px;
  padding: 0px !important;
}

.bkg-white {
  background-color: white;
}

.bordered-bottom-grey {
  border: 1px solid #E8E7E7;
}

.remove-btn-styles {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.remove-btn-styles:disabled {
  background: none;
	color: inherit;
	border: none;
	padding: 0;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.bg-clr-blue {
  background-color: #F4F9FB;
}

.btn-outline-round-style {
  padding: 8px 50px;
  border: 1px solid black;
  border-radius: 20px;
}

.modal-header-cross-btn {
  position: absolute;
  top: 20px;
  right: 20px;
}

.back-header-btn {
  display: flex;
  align-items: center;
  justify-content: start;
}

.back-left-arrow-btn-wrap {
  background: none;
	color: inherit;
	border: none;
	font: inherit;
	cursor: pointer;
	outline: inherit;
}

.back-header-btn .back-left-arrow-btn {
  height: 12px;
  width: 12px;
  border-style: solid;
  border-width: 2px 0px 0px 2px;
  rotate: -45deg;
}

* {
  --bs-header-border-bottom: 0 !important;
  --bs-header-padding: 0 !important;
  --bs-header-box-shadow: none !important;
  --bs-page-bg: hsl(0, 0%, 100%);
  --bs-header-bg-color: #201f2b;
}

.navbar {
  width: 100% !important;
  color: #fff !important;
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-brand-padding-y: 0.44375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.075rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.075rem;
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.475rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}

.navbar-settings {
  width: 100% !important;
  color: #fff !important;
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-brand-padding-y: 0.44375rem;
  /* --bs-navbar-brand-margin-end: 1rem; */
  --bs-navbar-brand-font-size: 1.075rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.075rem;
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 0.475rem;
  --bs-navbar-toggler-focus-width: 0.25rem;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex !important;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
  /* border: 1px solid #E8E7E7; */
}

.border-btm-header {
  border-style: solid;
  border-width: 0px 0px 1px 0px;
  border-color: #E8E7E7;
}

.nav-link {
  display: block;
  font-weight: var(--bs-nav-link-font-weight);
  color: rgb(255 255 255);
  transition: color 0.15s ease-in-out;
  font-size: larger;
}

.nav-link-s {
  display: block;
  font-weight: var(--bs-nav-link-font-weight);
  color: rgb(16, 15, 15);
  transition: color 0.15s ease-in-out;
  font-size: larger;
}

.nav-link-sa {
  display: block;
  font-weight: var(--bs-nav-link-font-weight);
  color: rgb(16, 15, 15);
  transition: color 0.15s ease-in-out;
  font-size: small;
}

.navbar-nav .btn-custom-date {
  --bs-nav-link-padding-x: 0;
  /* --bs-nav-link-padding-y: 0.5rem; */
  --bs-nav-link-color: #fff;
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0) !important;
  border-style: solid !important;
  border-color: #fff !important;
  border-width: 0.5px !important;
  border-radius: 0;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.custom-chart {
  height: 90%;
  background-color: #201f2b;
}

.custom-main-div {
  width: -webkit-fill-available !important;
  padding-right: 0px !important;
  background-color: #fff;
}

.nav-link h4 {
  padding: 0 !important;
}

.employee-row {
  margin-top: 1rem !important;
}

.semi .apexcharts-radialbar .apexcharts-datalabels-group {
  transform: translate(0, -15px) !important;
}

.apexcharts-legend-marker span {
  width: 45px !important;
  left: 0px;
  top: 0px;
  border-top-width: 0px !important;
  border-bottom-width: 1px !important;
  border-color: rgb(235 132 126) !important;
  border-radius: 0px;
  border-style: dashed !important;
}

.card-body-chart {
  flex: 1 1 auto;
  padding: 0 0 !important;
  color: var(--bs-card-color);
}

.fas {
  line-height: 1;
  font-size: large !important;
  color: var(--bs-text-muted);
}

.custom-outline {
  border-width: 1rem !important;
  background-color: #fff !important;
  border-color: var(--bs-primary) !important;
  color: var(--bs-primary) !important;
}

.header {
  display: flex;
  align-items: flex-start;
  position: fixed;
  height: 70px;
  transition: left 0.3s ease;
  z-index: 100;
  box-shadow: var(--bs-header-box-shadow);
  background-color: #ffffff;
  /* border-bottom: var(--bs-header-border-bottom); */
  top: 0;
  right: 0;
  left: 0;
}

@media (min-width: 992px) {
  .header .header-brand {
    height: 69px;
    padding: 0 25px;
    width: 265px;
    border-bottom: var(--bs-header-border-bottom);
    flex-shrink: 0;
  }
}

@media (max-width: 992px) {
  .nav-month-selector {
    margin-left: 50px;
  }

  .dash-wrap-responsive {
    display: flex;
    flex-direction: column;
  }

  .dash-wrap-responsive .wrap1 {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 2rem;
  }

  .dash-wrap-responsive .wrap2 {
    width: 100%;
  }

  .mixed-card-responsive {
    margin-bottom: 0.5rem;
  }
}


.custom-btn-modal {
  margin: 10px 0px 30px 0px;
  width: 30%;
  align-self: center;
}

.no-bold {
  font-weight: 500;
}

.image-container {
  padding: 0 30px;
  background-color: #fcfcfc;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border: 1px dashed rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  margin: 10px 20px;
  flex-direction: column;
}

.captialise {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 5%;
  font-family: 'Nunito', sans-serif;
}

.custom-footer {
  position: absolute;
  bottom: 0.3%;
  padding: 0px 20px;
  width: -webkit-fill-available;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.no-radius {
  border-radius: 2px !important;
}

.thumb {
  display: inline-flex;
  border-radius: 2px;
  border: 1px solid #eaeaea;
  margin-bottom: 8px;
  margin-right: 8px;
  width: 100px;
  height: 100px;
  padding: 4px;
  box-sizing: 'border-box';
}

.thumb-container {
  display: flex;
  flex-direction: 'row';
  flex-wrap: 'wrap';
  margin-top: 16px;
}

.custom-outline-button {
  margin: 10px;
  background-color: #fff;
  color: #0491bd;
  border: 1px #0491bd solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.775rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.475rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-outline-button-v2 {
  margin: 10px;
  background-color: #fff;
  color: #62909A;
  border: 1px #62909A solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.775rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.475rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-primary-delete-button {
  margin: 10px;
  background-color: #0491bd;
  color: #fff;
  border: 1px #0491bd solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.775rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.475rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.custom-primary-delete-button-v2 {
  margin: 10px;
  background-color: #62909A;
  color: #fff;
  border: 1px #62909A solid;
  --bs-btn-padding-x: 1.5rem;
  --bs-btn-padding-y: 0.775rem;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.1rem;
  --bs-btn-font-weight: 500;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: #181c32;
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 1px;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 0.475rem;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(39, 118, 155, 0.075);
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0.25rem rgba(var(--bs-btn-focus-shadow-rgb), 0.5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  border-radius: var(--bs-btn-border-radius);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.acres-text {
  font-size: 2.5rem;
  font-weight: bold;
  margin: 0px !important;
}

.acres-text-container{
  position: absolute;
  top: 32%;
  right: 20%;
  background-color: #fff;
  padding: 5px 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.titles-text {
  position: absolute;
  bottom: 36%;
  left: 30%;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
}

.top-5 {
  top: 27px;
}

.end-5 {
  right: 20px;
}

.custom-position {
  position: absolute;
  top: 42%;
  right: 7%;
  border: 1px solid rgb(0, 158, 247);
  padding: 5px 10px;
  background-color: #fff;
  border-radius: 5px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Works for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
.card-img-top {
  border-radius: 0px !important;
}
.bg-progress-green {
  background-color: #69be51;
  border-radius: 2px;
}


.bg-progress-blue {
  border-radius: 2px;

}

.text-muted-dark {
  color: #666666;
}

.custom-border {
  border: 0.5px solid #a4adba;
}

.custom-badge {
  background-color: rgba(0, 0, 0);
  border-radius: 7px;
  padding: 5px;
  position: absolute;
  top: 16px;
  z-index: 1111111;
  right: 7px;
  color: #fff;
  filter: blur(5px);
}

.custom-divider {
  border: 1px solid #e7e7e7 !important;
}


/* a[href^="http://maps.google.com/maps"]{display:none !important} */

.gmnoprint a, .gmnoprint span {
    display:none;
}
.gmnoprint div {
    background:none !important;
} 

.gm-style-cc{
  display: none;
}


.mt-70{
  margin-top: 70px;
}

.mb-70{
  margin-bottom: 70px;
}
/* .vertical-timeline {
 width: 100%;
 position: relative;
 padding: 1.5rem 0 1rem;
}

.vertical-timeline::before {
 content: '';
 position: absolute;
 top: 0;
 left: 67px;
 height: 100%;
 width: 4px;
 background: #e9ecef;
 border-radius: .25rem;
}

.vertical-timeline-element {
 position: relative;
 margin: 0 0 1rem;
}

.vertical-timeline--animate .vertical-timeline-element-icon.bounce-in {
 visibility: visible;
 animation: cd-bounce-1 .8s;
}
.vertical-timeline-element-icon {
 position: absolute;
 top: 0;
 left: 60px;
}

.vertical-timeline-element-icon .badge-dot-xl {
 box-shadow: 0 0 0 5px #fff;
}

.badge-dot-xl {
 width: 18px;
 height: 18px;
 border-radius: 9px;
 position: relative;
}
.badge:empty {
 display: none;
}


.badge-dot-xl::before {
 content: '';
 width: 10px;
 height: 10px;
 border-radius: 1.25rem;
 position: absolute;
 left: 50%;
 top: 50%;
 margin: -5px 0 0 -5px;
 background: #fff;
}

.vertical-timeline-element-content {
 position: relative;
 margin-left: 90px;
 font-size: 1rem;
}

.vertical-timeline-element-content .timeline-title {
 font-size: 1rem;
 text-transform: uppercase;
 margin: 0 0 .5rem;
 padding: 2px 0 0;
 font-weight: bold;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
 display: block;
 position: absolute;
 left: -55%;
 top: 0;
 padding-right: 10px;
 text-align: right;
 color: #000;
 font-size: 1rem;
 white-space: nowrap;
}

.vertical-timeline-element-content:after {
 content: "";
 display: table;
 clear: both;
} */

.forest-detail-card-sticky {
  height: fit-content;
  position: sticky;
  top: 0;
}

.forest-detail-card-sticky .card-body {
  border-radius: 0px 0px 10px 10px;
}

.card-overlay-text{
  color: #fff;
  font-weight: bold;
  font-size: 2rem;
  position: absolute;
  top: -14%;
}

.custom-updates-card{
      width: 100%;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
      height: 100%;
      max-height: 300px;
      object-fit: cover;
}

.overlay-div{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #fff;
    width:100%;
    height:100%;
    font-family: 'Roboto', sans-serif;
    background:linear-gradient(to bottom, rgba(255,255,255, 0) 60%, rgba(0,0,0, 1) 100%); 
}

.custom-height-35{
  max-height: 40%;
}

.height-50{
  height: 50%;
}

.custom-banner-container{
  background-color: #FFF7D1;
  border: 1px solid #FEC910;
  margin-top: 20px;
  margin-left: 20px;
  border-radius: 1rem;
  margin-right: 20px;
  padding: 10px 20px 0px 20px;
  background-image: url('../../assets/banner-background.svg');
  background-repeat: no-repeat;
  background-position: center right;

}

.custom-pt-0{
  padding-top: 0px !important;
}

.custom-pin{
  font-size: 2rem !important;
  color: red !important;
}

 /* TOGGLE SWITCH BTN - ALL EMPLOYEES, AVG PER EMPLOYEE */

.tg-toggle-container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  font-weight: 600;
}

.tg-label.inactive {
  color: #888888;
}

.tg-label.active {
  color: black;
}

.tg-switch {
  position: relative;
  display: inline-block;
  width: 26px;
  height: 18px;
}

.tg-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.tg-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 1px;
  right: 1px;
  bottom: 3px;
  background-color: white;
  border: 2px solid #5CADC9;
  border-radius: 15px;
  transition: 0.4s;
}

.tg-slider:before {
  position: absolute;
  content: '';
  height: 9px;
  width: 9px;
  left: 1px;
  bottom: 1px;
  background-color: white;
  border: 2px solid #5CADC9;
  border-radius: 50%;
  transition: 0.4s;
}

input:checked + .tg-slider:before {
  transform: translateX(9px);
}

.tg-slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 800px) {
  .spider-chart-resp {
    margin-bottom: 20px;
  }
}

.allocation .progress-bar{
  background-color: #409BB8;
}

.table-val-custom{
  min-width: 10%;
  margin-left: 2%;
  border: 1px solid #0491BD;
  border-radius: 10px;
  padding: 8px 20px;
  background-color: #fff;
  color: #0491BD ;
  align-self: center;
}

.custom-slider-div{
  position: absolute;
  display: flex;
  bottom: -4rem;
  border: 1px solid #d8d8d8;
  padding: 10px 20px;
  border-radius: 20px;
  background-color: #fff;
  z-index: 1;
}

button:disabled,
button[disabled]{
  border: 1px solid #999999;
  background-color: #cccccc;
  color: #666666;
  cursor:default;
}

@media screen and (max-width: 800px) {
  .spider-chart-resp {
    margin-bottom: 20px;
  }
}


/* NEW TIMELINE */
ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: ' ';
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #8a8a8a;
  left: -6px;
  width: 15px;
  height: 15px;
  z-index: 401;
}
.timeline-line-grey {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 130%;
  z-index: 400;
  left: 0px;
}

.bold{
  font-weight: bold;
}

.center-align{
  text-align: center;
}

.forest-logs-wrapper {
  height: 72vh;
}

.forest-timeline-logs {
  max-height: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.timeline-li-emoji {
  position: absolute; 
  left: -30px;
  top: -3px;
}

.hover-truncate-ellipsis {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    transition: all .2s linear;
    white-space: nowrap;
    max-width: 20rem;
}
.hover-truncate-ellipsis:focus, .hover-truncate-ellipsis:hover {
  color:transparent;
  &:after{
    content:attr(data-text);
    display:block;
    overflow: visible;
    text-overflow: inherit;
    position: absolute;
    left:10px;
    top:-10px;
    width: auto;
    border-radius: 5px;
    padding: .5rem 1rem;
    white-space: normal;
    word-wrap: break-word;
    font-size: 1.6rem;
    color:black;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
    background: #fff;
  }
}

.logo-image{
  border: 0.5px solid #d8d8d8;
}

.org-pic-edit-icon {
  color: #0491bd;
  background-color: #ffffffe0;
  position: absolute;
  top: 10px;
  right: 10px; 
  height: 22px;
  width: 22px;
  border-radius: 2px;
}

.wrap-custom-default-input-styles {
  position: relative;
	border: 1px solid #BABABA;
	border-radius: 8px;
	padding: 8px;
}

.custom-default-input-styles {
  width: 100%;
  border-width: 0;
	outline: none;
	font-size: 15px;
	font-weight: 600;
}