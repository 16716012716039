.blank-page-view {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: white;
  position: relative;
}

.page-title-text {
  font-size: 1.8rem;
  font-weight: 600;
}

.cloud-upload-bg {
  background-image: url('/public/media/svg/shapes/cloud_upload_bg.svg');
}

.bg-image-bottom-right {
  position: absolute; 
  bottom: 0;
  right: 0;
}

.centered-div-wrap {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: 100%;
  align-items: center;
  justify-content: center;
}

.label-info-employees {
  font-size: 1rem;
  color: #9c9c9c;
}

.wrap-loader-utility-bill {
  display: flex;
  justify-content: center;
  margin-top: 15%;
}

.report-page-header-right {
  display: flex;
}

.date-range-picker-wrap {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.date-range-picker-div {
  -webkit-box-shadow: 2px 2px 11px -3px rgba(105,105,105,1);
  -moz-box-shadow: 2px 2px 11px -3px rgba(105,105,105,1);
  box-shadow: 2px 2px 11px -3px rgba(105,105,105,1);
  position: absolute; 
  top: 100%; 
  right: 0%;
  z-index: 100000;
}

.download-report-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1F7F9D;
  font-size: 1.2rem;
  font-weight: 600;
  margin-left: 20px;
}

.report-action-btn-wrapper {
  z-index: 10000;
  display: flex;
  position: relative;
  justify-content: flex-end;
  background-color: #fff;
}

.report-action-btn {
  padding: 5px 10px;
  background-color: #fff;
  margin-right: 20px;
  margin-bottom: 10px;
  border: solid 1px lightgrey;
  border-radius: 5px;
  width: 70px;
}

.bg-blue {
  background-color: #3d91ff;
  color: #fff;
}

.accordion-button {
  width: auto;
  padding: 0px 20px;
}

.report-sec-header {
  font-weight: 800;
  margin-right: 20px;
  margin-top: 10px;
}

.accordion .accordion-header {
  cursor:default;
  display: flex;
  justify-content: space-between;
  border-width: 0px 0px 1px 0px;
  border-style: solid;
  border-color: #E8E7E7;
}

.accordion-right-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5.1%;
  width: 35%;
}

.accordion-right-header h4 {
  width: 100px; 
  text-align: right;
}

.accordion-body {
  padding: 0px;
  z-index: 0;
}

.table-title-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-style: solid;
  border-color: #E8E7E7;
  border-width: 0px 0px 1px 0px;
}

.table-title-text {
  /* margin-left: 20px; */
  font-weight: 600;
}

.table-cell-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 40px;
  border-style: solid;
  border-color: #E8E7E7;
  border-width: 0px 0px 1px 0px;
  color: #707070;
  font-weight: 600;
}

.table-cell-title {
  margin-left: 40px;
}

.table-cell-values-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 35%;
  padding-right: 5.1%;
}

.table-cell-value {
  width: 100px; 
  text-align: right;
}

/*
.report-sec-header:active {
  color: #1F7F9D;
  background-color: #ffffff;
}

.accordion-button:active {
  color: #1F7F9D;
  background-color: #ffffff;
} */