@keyframes fillAnimation{
  0%{transform : rotate(-45deg);}
  50%{transform: rotate(135deg);}
}
  
@keyframes fillGraphAnimation{
  0%{transform: rotate(0deg);}
  50%{transform: rotate(180deg);}
}


.semi-donut-model-net-zero {
  width: 160px;
  height: 80px;
  position: relative;
  text-align: center;
  color: #fff;
  border-radius: 160px 160px 0 0;
  overflow: hidden;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  box-sizing : border-box;
  &:before, &:after{
    content: '';
    width: 160px;
    height: 80px;
    border:20px solid #A687CA;
    border-top: none;
    position: absolute;
    transform-origin :50%  0% 0;
    border-radius: 0 0 160px 160px ;
    box-sizing : border-box;
    left: 0;
    top: 100%;
  }
  &:before {
    border-color:rgba(0,0,0,.15);
    transform: rotate(180deg);
  }
  &:after{
      z-index: 3;
      animation : 1s fillGraphAnimation ease-in;
    transform: rotate( calc( 1deg * ( var(--percentage) * 1.8 ) ) );
  }
  &:hover{
      &:after{
        opacity: .8;
        cursor: pointer;
      }
  }
}


.reduction-initiative-percentage-range {
  -webkit-appearance: none;
  width: 100%;
  height: 4px;
  outline: none;
  border-radius: 2px;
}

.reduction-initiative-percentage-range:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.reduction-initiative-percentage-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 22px;
  height: 22px;
  background: radial-gradient(circle, #8962C2 0%, #5a529c 70%, #403174 100%);
  border-radius: 50%;
  cursor: pointer;
}

.reduction-initiative-percentage-range::-moz-range-thumb {
  width: 22px;
  height: 22px;
  background: radial-gradient(circle, #8962C2 0%, #5a529c 70%, #403174 100%);
  border-radius: 50%;
  cursor: pointer;
}

/* Tooltip with arrow pointed upwards */
.reduction-initiative-percentage-range-tooltip {
  position: absolute;
  top: 30px;
  transform: translateX(-50%);
  background-color: #6c6c6c;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.9rem;
  white-space: nowrap;
  z-index: 1;
}

.reduction-initiative-percentage-range-tooltip:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.reduction-initiative-percentage-range-tooltip::before {
  content: '';
  position: absolute;
  bottom: 26px;
  left: 50%;
  transform: translateX(-50%);
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #6c6c6c transparent;
}