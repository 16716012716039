.line-bar {
  height: 2px;
  width: 30%;
}

.polls-datepicker-styles {
  padding: 8px 10px;
  font-size: 14px;
  width: 100%;
  font-weight: 600;
  border: 1px solid #BABABA;
  border-radius: 8px;
}

.radio-button-styles {
  height: 20px;
  width: 20px; 
  accent-color: #0491BD;
  cursor: pointer;
}

.polls-type-table-styles {
  border-collapse: collapse;
  border-radius: 15px; 
  overflow: hidden; 
}

.polls-type-table-styles th, td {
  border: 1px solid #ddd;
  padding: 15px; /* Adds padding inside table cells */
  text-align: left; /* Aligns text to the left */
}

.polls-type-table-styles th {
  background-color: #f8f7f7; /* Light grey background for table headers */
}

.create-polls-bullet-points {
  list-style-type: disc; /* Ensures bullets are used */
}
.create-polls-bullet-points li::marker {
  font-size: 16px;
  color: #00556B; /* Sets the bullet color (specific to bullet points) */
}

.wrap-create-polls-participant-input-container {
  display: flex;
  overflow: scroll;
  flex-wrap: wrap;
  scroll-behavior: auto;
  flex-direction: row;
  align-content: flex-start;
	padding: 8px;
  height: 200px;
  min-width: 200px;
	border: 1px solid #BABABA;
	border-radius: 5px;
}


