.wrap-create-custom-group-search-input {
	display: flex;
	justify-content: center;
	align-items: center;
  width: 35%;
  min-width: 200px;
	border: 1px solid grey;
	border-radius: 5px;
	padding: 8px;
}

.employee-search-input {
	border-width: 0;
	outline: none;
	font-size: 15px;
	font-weight: 600;
  width: 100%;
}

.wrap-create-custom-group-modal-input {
	display: flex;
  position: relative;
	justify-content: center;
	align-items: center;
  min-width: 200px;
	border: 1px solid grey;
	border-radius: 5px;
	padding: 8px;
}

.wrap-create-custom-group-modal-input-emp {
	display: flex;
  overflow: scroll;
  flex-wrap: wrap;
  scroll-behavior: auto;
  flex-direction: row;
  align-content: flex-start;
	padding: 8px;
  height: 200px;
  min-width: 200px;
	border: 1px solid grey;
	border-radius: 5px;
}

.custom-tag-member-card {
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  background-color: #F1F1F1;
  color: #666666;
  height: fit-content;
  padding: 5px 10px;
  margin: 5px;
  border-radius: 20px;
  cursor:default;
}

.custom-groups-container {
  flex: 3;
  border-radius: 5px;
  border: 1px solid grey;
  margin-right: 10px;
  overflow: hidden;
}

.group-memebers-container {
  flex: 6;
  border-radius: 5px;
  border: 1px solid grey;
  max-height: fit-content;
}

.header-custom-group-contrainer {
  border: 0px solid grey;
  border-bottom-width: 1px;
  padding: 15px 20px;
  max-height: fit-content;
}

.body-custom-group-contrainer {

}

.org-tag-reports-title-container {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  margin-bottom: 20px;
  background-color: #F9F9F9;
  border-radius: 15px;
}

.header-group-memebers-container {
  display: flex;
  flex-direction: row;
  border: 0px solid grey;
  border-bottom-width: 1px;
  padding: 15px 20px;
}

.header-group-memebers-container .header1 {
  width: 45%;
}

.header-group-memebers-container .header2 {
  width: 55%;
}

.body-group-memebers-container {

}

.cell-wrap-custom-groups {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 20px;
}

.custom-group-display-color-card {
  padding: 5px 10px;
  border: 1px solid;
  border-radius: 5px;
  margin-right: 10px;
  width: fit-content;
}

.cell-wrap-custom-members {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0px;
  margin: 0px 20px;
  border: 1px solid #E5EAEE;
}

.custom-tag-delete-modal-trash-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100px;
  border-radius: 50px;
  background-color: #F4F6F6;
}

.custom-tag-search-results-wrap {
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  min-width: 60%;
  width: fit-content;
  background-color: white;
  border-radius: 5px;
  top: 110%;
  z-index: 1000;
  -webkit-box-shadow: -1px 9px 32px 0px rgba(184,184,184,1);
  -moz-box-shadow: -1px 9px 32px 0px rgba(184,184,184,1);
  box-shadow: -1px 9px 32px 0px rgba(184,184,184,1);
}

.custom-tag-emp-search-cell {
  display: flex;
  text-align: start;
  padding: 10px 10px;
  border: 1px solid rgb(212, 212, 212); 
}

.custom-tag-emp-search-cell:hover {
  background-color: #E5EAEE;
}

.custom-tag-emp-search-email-text {
  color: rgb(106, 106, 106); 
  margin: 0px;
  margin-left: 10px;
  font-size: 12px;
}

.tag-color-picker-btn {
  height: 25px; 
  width: 25px; 
  border-radius: 5px;
}

.tag-color-picker-container {
  position: absolute;
  background-color: #fff;
  top: 110%; 
  right: 0px;
  padding: 5px;
  z-index: 10;
  border-radius: 5px;
  -webkit-box-shadow: -1px 9px 32px 0px rgba(184,184,184,1);
  -moz-box-shadow: -1px 9px 32px 0px rgba(184,184,184,1);
  box-shadow: -1px 9px 32px 0px rgba(184,184,184,1);
}

.select-custom-tag-wrap {
  width: 80%; 
}

.create-tag-model-title {
  width: 100%;
  padding: 5px 10px;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  background-color: #f0f6fc;
  border: 1px solid #afaeae;
  border-width: 0px 0px 1px 0px;
  border-radius: 5px;
}

.empty-custom-cell-wrap {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0px;
  background-color: #ffffff;
  border-radius: 20px;
  border-width: 0px;
  color: rgb(111, 111, 111);
}

/* Scrollbar  */

.scrollbar-thumb-grey {
  padding-bottom: 5px;
}

.scrollbar-thumb-grey::-webkit-scrollbar {
  width: 1.5px;
  height: 1.5px;
}
.scrollbar-thumb-grey::-webkit-scrollbar-thumb {
  background: #00baae7d !important;
}